import { styled, notDesktop, Text } from "@obvio/app";
import { FadeIn, Map } from "@obvio/template";
import { Stack } from "@obvio/ui";

import { CtaLink } from "@/components/CtaLink";
import { MAP_MARKERS, MAP_STYLE } from "@/constants/map";

import type { ReactElement } from "react";

const MapPills = styled(Stack)`
  position: absolute;
  top: ${(theme) => theme.spacing.large};
  left: ${(theme) => theme.spacing.large};
`;

const Marker = styled.div<{ $active: boolean }>`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 99999px;
  background: white;
  z-index: ${(_, { $active }) => ($active ? 3 : 2)};
  position: relative;
  cursor: pointer;
`;

const MarkerBody = styled.div`
  padding: ${(theme) => theme.spacing.small};
  color: white;
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  width: auto;
  span {
    color: white;
    white-space: nowrap;
  }
`;

const Destination = styled.div`
  font-family: ${(theme) => theme.secondaryFont};
  background: ${(theme) => theme.colors.primary.dark};
  padding: 1rem 1.5rem;
  border-radius: calc(${(theme) => theme.radius.small} / 2);
  height: fit-content;
  p {
    color: white;
  }
  p:first-of-type {
    font-size: 0.75rem;
    color: #979c9b;
  }
  @media ${notDesktop} {
    display: none;
  }
`;

export function GolfMap(): ReactElement {
  return (
    <FadeIn>
      <Map
        defaultZoom={10}
        defaultCenter={{
          lat: 51.924_619_106_324_26,
          lng: 20.790_213_814_401_348,
        }}
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API ?? ""}
        markers={MAP_MARKERS}
        marker={(data) => (
          <Marker onClick={() => data.onClick?.()} $active>
            <MarkerBody>
              <Text tag="span" as="h3">
                {data.activeMarker?.title}
              </Text>
            </MarkerBody>
          </Marker>
        )}
        options={{
          styles: MAP_STYLE,
          disableDefaultUI: true,
        }}
      />
      <MapPills>
        <Destination>
          <Stack kind="vertical" spacing="0.1rem">
            <Text>Golf przy Pałacu Mała Wieś</Text>
            <Text>Mała Wieś 40, Belsk Duży pod Warszawą</Text>
          </Stack>
        </Destination>
        <Destination>
          <CtaLink
            href="https://www.google.com/maps/dir//Pa%C5%82ac+Ma%C5%82a+Wie%C5%9B,+Ma%C5%82a+Wie%C5%9B+40,+05-622+Ma%C5%82a+Wie%C5%9B/@51.8246143,20.7876381,17z/data=!4m17!1m7!3m6!1s0x47191762b523b14f:0xae6beba277372eaf!2zUGHFgmFjIE1hxYJhIFdpZcWb!8m2!3d51.824611!4d20.790213!16s%2Fg%2F11cs69l78s!4m8!1m0!1m5!1m1!1s0x47191762b523b14f:0xae6beba277372eaf!2m2!1d20.790213!2d51.824611!3e2?entry=ttu"
            text="Wyznacz trase"
          />
        </Destination>
      </MapPills>
    </FadeIn>
  );
}
